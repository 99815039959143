.contact_form {
    position: relative;
    z-index: 5;
    width: calc(100% - 100px);
    min-height: 0;
    padding-top: 10rem;
    max-width: 900px;
    margin: 0 auto;

    .success {
        font-size: 1.7rem;
        padding: 3rem;
        text-align: center;
        background-color: $background;
    }

    .form_wrap {
        position: relative;
        width: 90%;
        margin: auto auto 5rem auto;

        form {
            width: 100%;
            display: block;

            .Form__Success__Message {
                background: unset;
                color: $accent3;
                font-style: italic;
            }

            section {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
            }

            .cell {
                width: calc(50% - 10px);
                margin-bottom: 30px;

                &.full {
                    width: 100%;
                }

                input, textarea {
                    width: 100%;
                    background-color: #526736;
                    padding: 20px 10px;
                    font-family: $font_primary;
                    font-size: 1.1rem;
                    color: $accent3;

                    &::placeholder {
                        font-family: $font_primary;
                        color: $text_primary;
                        color: #8fbc8f;
                        font-style: italic;
                    }
                }

                textarea {
                    height: 260px;
                }

                span {
                    //validation message styling here
                    color: $accent;
                    font-style: italic;
                }
            }

            .forest-submit-button {
                justify-self: flex-end;
                margin: 0 0 0 auto;
                background-color: $accent;
                width: calc(50% - 10px);

                button {
                    cursor: pointer;
                    color: $background;
                    border: none;
                    font-family: $font_headline;
                    font-weight: 900;
                    font-size: 1.1rem;
                    padding: 20px 10px;
                    width: 100%;
                    display: block;
                    text-transform: uppercase;
                    margin: 0;
                }
            }
        }

        .epi-form-wrapper {
            width: 100%;

            section {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
            }

            .cell {
                width: calc(50% - 10px);
                margin-bottom: 30px;

                &.full {
                    width: 100%;
                }

                input, textarea {
                    width: 100%;
                    background-color: #526736;
                    padding: 20px 10px;
                    font-family: $font_primary;
                    font-size: 1.1rem;
                    color: $accent3;

                    &::placeholder {
                        font-family: $font_primary;
                        color: $text_primary;
                        color: #8fbc8f;
                        font-style: italic;
                    }
                }

                textarea {
                    height: 260px;
                }

                span {
                    //validation message styling here
                    color: $accent;
                    font-style: italic;
                }
            }

            .forest-submit-button {
                justify-self: flex-end;
                margin: 0 0 0 auto;
                background-color: $accent;
                width: calc(50% - 10px);

                button {
                    cursor: pointer;
                    color: $background;
                    border: none;
                    font-family: $font_headline;
                    font-weight: 900;
                    font-size: 1.1rem;
                    padding: 20px 10px;
                    width: 100%;
                    display: block;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@include phone_portrait {
    .contact_form {
        h1.lines {
            span {
                font-size: 1.0rem;
            }
        }
        .form_wrap {
            width: 100%;
        }
    }
}


.press_kit .inner a {
    text-decoration: none;
}
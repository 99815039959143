@mixin absolute-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin headline {
    font-size: 5rem;
    font-weight: 700;
    opacity: .75;
    line-height: 1;
    color: #fff;
    letter-spacing: .2rem;
    @include basic_mobile {
        font-size: 3rem;
        line-height: 1.2;
    }
}

@mixin small_headline {
    font-size: 2.3rem;
    font-weight: 700;
    opacity: .75;
    line-height: 1;
    color: #fff;
    letter-spacing: .02rem;
    @include basic_mobile {
        font-size: 1.2rem;
    }
}

@mixin basic_mobile {
    @media all and (max-width: 1024px) {
        @content;
    }
}

@mixin mobile_portrait {
    @media all and (max-width: 896px) and (orientation: portrait) {
        @content;
    }
}

@mixin phone_portrait {
    @media all and (max-width: 430px) and (orientation: portrait) {
        @content;
    }
}

.mobile_only {
    display: none !important;
}

@include phone_portrait {
    .mobile_only {
        display: block !important;
    }
    .desktop_only {
        display: none !important;
    }
}

.preload * {
    transition: none !important;
}
  
* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
}

body {
    width: 100%;
    font-family: $font_primary;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    color: $text_primary;
    background: $background;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body.secondary_bg {
    background: $text_secondary;
}

main {
    article {
        min-height: 100vh;
    }
}

.tan {
    color: $text_primary !important;
}

article.inspiration_wrap .dark_bg {
    padding-top: 10rem;
    @include basic_mobile {
        padding-top: 6rem;
        margin-bottom: 4rem;
    }
    @include phone_portrait {
        margin-bottom: 2rem;
    }
}

.dark_bg {
    position: relative;
    width: 100%;
    height: auto;
    background-color: $background2;
}

img.bottom_edge {
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 100%;
    height: auto;
    display: block;
    @include basic_mobile {
        bottom: -10px;
    }
    @include phone_portrait {
        bottom: -3px;
    }
}

img.rounded_corners {
    border-radius: 30px;
}

img.top_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
    pointer-events: none;
    display: block;
}

.foreground_wrap {
    position: relative;
    width: 100%;
    margin: 0 auto;
    margin-top: 70vh;
    .mobile_bg_cover {
        pointer-events: none;
        position: absolute;
        top: 360px;
        left: 0;
        width: 100%;
        height: calc(100% - 360px);
        background-color: $background;
        img.top_edge {
            position: absolute;
            top: -3px;
            left: 0;
            width: 100%;
            height: auto;
        }
        &.about_us {
            top: 340px;
            height: calc(100% - 340px);
        }
    }
    &.top {
        margin-top: 20vh;
    }
    &.full {
        .content {
            .wrap {
                width: 100%;
                padding: 0;
                position: relative;
                z-index: 2;
            }
        }
    }
    .intro {
        position: relative;
        z-index: 1;
        width: 93%;
        margin: 0 auto;
        padding: 6rem 0 2rem ;
        background-color: $background;
        .text_block, .pull_quote {
            padding: 0;
            max-width: 1200px;
        }
        .text_block {
            width: 100%;
        }
        .text_block.narrow {
            padding: 0;
        }
    }
    .content {
        position: relative;
        width: 100%;
        margin: 0 auto;
        background-color: $background;
        padding-bottom: 6rem;
        img.top_edge {
            position: absolute;
            top: -20px;
            left: 0;
            width: 100%;
            height: auto;
            display: block;
        }
        .wrap {
            width: 100%;
            padding: 0;
            margin: 0 auto;
            position: relative;
            z-index: 2;
            .narrow {
                width: 100%;
                max-width: 1200px;
                margin: 0 auto;
            }
        }
    }
    .background_fill {
        z-index: -1;
        margin-top: -30rem;
        padding: 10rem 0 22rem 0;
        position: relative;
        &.about {
            display: none;
        }
    }
}

@include basic_mobile {
    .foreground_wrap {
        .intro {
            width: calc(100% - 100px);
            padding: 0;
            .text_block, .pull_quote {
                padding: 0;
                .inner {
                    width: 100%;
                }
            }
        }
        .content {
            img.top_edge {
                top: -10px;
            }
            .wrap {
                .narrow {
                    width: calc(100% - 100px);
                    padding: 0;
                }
            }
        }
        &.top {
            margin-top: 19vh;
        }
        &.full {
            margin-top: 0;
        }
        .home_intro {
            align-items: center;
            justify-content: center;
            height: 540px;
            margin-top: 60px;
        }
    }
}

@include phone_portrait {
    .foreground_wrap {
        .intro {
            margin-top: 3rem;
            width: calc(100% - 40px);
            .text_block {
                text-align: center;
            }
        }
        &.full {
            margin-top: 0;
        }
        .home_intro {
            height: 100vh;
            align-items: center;
            justify-content: center;
            margin-top: 0;
        }
        .content {
            padding-bottom: 3rem;
            img.top_edge {
                top: -5px;
            }
            .wrap {
                .narrow {
                    width: calc(100% - 40px);
                }
            }
        }
    }
}

.cell {
    position: relative;
    .mediawrap {
        position: relative;
        width: 100%;
        height: 0;
        img, video {
            @include absolute-cover;
        }
    }
}

.playIcon {
    display: block;
    width: 90px;
    height: auto;
    cursor: pointer;
    @include absolute-center;
    transform: scale(1) translate(-50%, -50%);
    transform-origin: center;
    transition: transform .3s;
    &:hover {
        transform: scale(.95) translate(-50%, -50%);
    }
}

@include mobile_portrait {
    .playIcon {
        width: 45px;
    }
}

h1, h2, h3 {
    font-family: $font_headline;
    font-weight: 500;
}

h1 + h3.small_headline {
    margin-top: 10px;
}

h1 {
    @include headline;
}

h2 {
    letter-spacing: .1rem;
}

h3.small_headline {
    @include small_headline;
}

h3 {
    font-size: 1.2rem;
    letter-spacing: .2rem;
    &.small {
        font-size: 1.8rem;
        &.lines {
            span {
                opacity: 1;
            }
        }
    }
    &.accent {
        text-align: center;
        margin: 5rem auto 0 auto;
        opacity: .65;
        color: $accent;
        letter-spacing: .1rem   ;
    }
}
h1, h2, h3 {
    &.lines {
        width: 100%;
        margin: 0 auto 1rem auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        &.blue {
            svg {
                path {
                    fill: $text_secondary;
                }
            }
        }
        svg {
            flex: 3;
        }
        span {
            display: block;
            flex: 1;
            text-align: center;
            opacity: .65;
            white-space: nowrap;
            margin: 0 10px;
            font-size: 2.2rem;
            &.small {
                font-size: 1.5rem;
                letter-spacing: .02rem;
            }
            &.medium {
                font-size: 1.7rem;
            }
        }
    }
}

h1.lines {
    font-size: 2.3rem;
    span {
        opacity: 1;
        color: $text_primary;
    }
}

@include basic_mobile {
    h1 {
        font-size: 3rem;
    }
    h3 {
        font-size: 1.3rem;
    }
    h1, h2, h3 {
        &.lines {
            flex-direction: column;
            align-items: center;
            margin-bottom: 1rem;
            span {
                order: 1;
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }
            svg {
                order: 2;
                width: 100%;
            }
            svg:last-child {
                display: none;
            }
        }
    }
}

@include mobile_portrait {
    h3 {
        font-size: .9rem;
        &.lines {
            span {
                &.small {
                    font-size: 1.9rem;
                }
            }
        }
        &.accent {
            font-size: 1.4rem;
            letter-spacing: unset;
            margin: 3rem auto 0 auto
        }
    }
}

img.branch_right {
    position: absolute;
    z-index: 1;
    top: 80px;
    right: 0;
    display: block;
    width: 500px;
    height: auto;
}

img.branch_left {
    position: fixed;
    z-index: 1;
    bottom: 0;
    display: block;
    width: 468px;
    height: auto;    
}

@include basic_mobile {
    img.branch_right, img.branch_left {
        display: none;
    }
}

p.large {
    font-size: 1.6rem;
}

p.medium {
    font-size: 1.2rem;
}

@include basic_mobile {
    p.large {
        font-size: 1.4rem;
    }
    p.medium {
        font-size: 1rem;
    }
}

button {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    border: 1px solid $accent2;
    color: $accent2;
    border-radius: 50px;
    padding: 9px 10px;
    font-family: $font_primary;
    font-size: .9rem;
    font-weight: 400;
    background-color: transparent;
    transition: background-color .2s, border-color .2s, color .2s;
    &.box {
        border-radius: 5px;
        padding: 9px 40px;
    }
    &.headline_font {
        font-family: $font_headline;
    }
    img {
        margin-right: 5px;
        padding-top: 1px;
    }
    &:hover {
        background-color: #fff;
    }
}

@include phone_portrait {
    button {
        font-size: .8rem;
        img {
            height: 12px;
            width: auto;
        }
    }
}
header {
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: $accent;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .logo {
        margin-left: 25px;
        width: 250px;
        position: relative;
        z-index: 2;
        font-family: $font_headline;
        font-weight: 700;
        font-size: 1.45rem;
        a {
            text-decoration: none;
            color: $background;
            transition: color .2s;
        }
        img {
            display: block;
            width: auto;
            height: 19px;
        }
    }
    .mobile_line {
        position: absolute;
        z-index: 10;
        top: 65px;
        left: 25px;
        width: calc(100% - 50px);
        opacity: 0;
        transition: opacity .3s;
    }
    nav {
        width: calc(100% - 550px);
        color: $text_secondary;
        font-family: $font_headline;
        font-weight: 500;
        position: relative;
        z-index: 1;
        ul {
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            li {
                margin: 0 1.5rem;
                a {
                    text-transform: uppercase;
                    color: currentColor;
                    text-decoration: none;
                    position: relative;
                    font-size: .9rem;
                    transition: color .2s;
                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -2px;
                        width: 0;
                        height: 1px;
                        background-color: $accent2;
                        transition: width .2s;
                    }
                    &:hover, &.pageOn {
                        color: #000;
                        &:after {
                            width: 90%;
                        }
                    }
                }
            }
        }
        img, .social {
            display: none;
        }
    }
    .menuBtn {
        position: absolute;
        top: 20px;
        right: 25px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        z-index: 3;
        display: none;
    
        .line {
            height: 3px;
            width: 100%;
            display: block;
            position: absolute;
            right: 0;
            background-color: $background;
            opacity: 1;

            transform: rotate(0deg);

            transition-property: background-color, opacity, transform, top;
            transition-duration: .2s, .1s, .3s, .3s;
            transition-delay: 0s, 0s, 0s, 0s;

            &:nth-child(1) {
                top: 0px;
            }

            &:nth-child(2) {
                top: 8px;
            }

            &:nth-child(3) {
                top: 16px;
            }
            
        }
        
    }
}

@media all and (max-width: 1330px) {
    header {
        .logo {
            width: 200px;
            font-size: 1.2rem;
            img {
                height: 15px;
            }            
        }
        nav {
            width: calc(100% - 450px);
            ul {
                li {
                    a {
                        font-size: .8rem;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 1200px) {
    header {
        .logo {
            width: 250px;
            font-size: 1.65rem;
            img {
                height: 19px;
            }
        }
        .mobile_line {
            display: block;
        }
        nav {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(37,90,94,.95);
            pointer-events: none;
            transform: translateY(-100%);
            transition: transform .7s;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            ul {
                flex-direction: column;
                margin-top: 150px;
                opacity: 0;
                transition: opacity .5s;
                li {
                    a {
                        font-size: 2rem;
                        color: $accent3;
                        text-transform: capitalize;
                        display: block;
                        padding: 1rem 0;
                        &:after {
                            bottom: 3px;
                        }
                    }
                }
            }
            .social {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                margin: 3rem auto 0 auto;
                opacity: 0;
                transition: opacity .3s;
                a {
                    display: block;
                    margin: 0 1rem;
                    svg {
                        height: 25px;
                        width: auto;
                    }
                }
            }
            img.left_branch {
                position: absolute;
                top: 50%;
                left: 0;
                width: 40%;
                height: auto;
                display: block;
                opacity: 0;
                transition: opacity .3s;
            }
            img.right_branch {
                position: absolute;
                bottom: 0%;
                right: 0;
                width: 65%;
                height: auto;
                display: block;
                opacity: 0;
                transition: opacity .3s;
            }
        }
        .menuBtn {
            display: block;
        }
    }
}

@include phone_portrait {
    header {
        .logo {
            width: auto;
            font-size: 1.1rem;
            img {
                height: 13px;
            }
        }
        .mobile_line {
            top: 55px;
        }
        nav {
            ul {
                margin-top: 100px;
                li {
                    a {
                        font-size: 1.2rem;
                        padding: 6px 0;
                    }
                }
            }
            img.left_branch {
                width: 45%;
                top: 60%;
            }
        }
    }
}

.menuOpen {
    header {
        .mobile_line {
            opacity: 1;
        }
        .logo {
            a {
                color: $accent;
            }
        }
        nav {
            transform: translateY(0);
            pointer-events: all;
            ul {
                opacity: 1;
                transition: opacity .5s .6s;
            }
            .social {
                opacity: 1;
                transition: opacity .5s .9s;
            }
            img.left_branch {
                opacity: 1;
                transition: opacity 1s .7s;
            }
            img.right_branch {
                opacity: 1;
                transition: opacity 1s 1s;
            }
        }
        .menuBtn {
            .line {
                background-color: $accent;
                &:nth-child(1) {
					top: 8px;
					transform: rotate(45deg);
				}

				&:nth-child(2) {
					opacity: 0;
					transition: opacity .1s 0s;
				}

				&:nth-child(3) {
					top: 8px;
					transform: rotate(135deg);
				}
            }
        }
    }
}
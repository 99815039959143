header {
    .logo {
        a {
            text-transform: uppercase;
        }
    }
}

@media all and (max-width: 1200px) and (orientation: landscape) {
    .menuOpen header nav {
        img.right_branch {
            top: 50%;
            width: 35%;
            right: 0;
        }
        img.left_branch {
            top: 50%;
            width: 35%;
            left: 0;
        }
    }
}

@media all and (max-width: 992px) and (orientation: landscape) {
    .menuOpen header nav {
        ul li a {
            font-size: 1.2rem;
            padding: 6px;
        }
    }
}
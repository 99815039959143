.logos {
    width: 75%;

    .inner {
        gap: 3rem;
        justify-content: center;

        .cell {
            flex-basis: 30%;
            flex-grow: 1;

            &.logo-small {
                flex: 0 1 20%;
            }

            .img-wrap {
                img {
                    max-width: 100%;
                }
            }
        }
    }
}

@include basic_mobile {
    .logos {
        width: 80%;
    }
}

@include mobile_portrait {
    .logos {
        .inner {
            .cell {
                width: 75%;

                &.logo-small {
                    width: 30%;
                }
            }
        }
    }
}

.grid.two_col {
    .cell {
        .mediawrap {
            padding-top: 56%;
        }
    }
}

.contacts {
    z-index: 2;

    .cell {
        h3 {
            text-transform: uppercase;
        }
    }
}

@include basic_mobile {
    .text_block {
        .inner {
            .cell.stacked_logo {
                margin: 2rem auto;
            }
        }
    }
}

.centered {
    .cell {
        .mediawrap {
            img {
                z-index: 2;
            }
        }
        .playIcon {
            z-index: 3;
        }
    }
    .cell.playing {
        .mediawrap {
            img {
                display: none;
            }
        }
    }
}

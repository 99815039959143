footer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $accent3;
    background-color: $background;
    .inner {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto 2rem auto;
        img {
            display: block;
            width: 175px;
            height: auto;
            margin: 0 auto 3rem auto;
        }
        nav {
            margin: 0 auto 2rem auto;
            font-family: $font_headline;
            font-weight: 500;
            ul {
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                li {
                    margin: 0 20px;
                    a {
                        color: currentColor;
                        text-decoration: none;
                        position: relative;
                        transition: color .2s;
                        &:hover {
                            color: $accent;
                        }
                    }
                    .social {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        a {
                            display: flex;
                            margin: 0 7px;
                            svg {
                                height: 15px;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
        .copyright {
            p {
                font-size: .9rem;
            }
        }
    }
    .background {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        pointer-events: none;
        z-index: 1;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

.secondary_bg {
    footer {
        background-color: $text_secondary;
    }
}

@include phone_portrait {
    footer {
        .inner {
            img {
                width: 130px;
            }
            nav {
                ul {
                    flex-direction: column;
                    li {
                        padding: 5px 0;
                        a {
                            font-weight: 400;
                        }
                        .social {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    }
}
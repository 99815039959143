.hero {
    position: relative;
    width: 100%;
    .background {
        position: relative;
        width: 100%;
        height: auto;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    .foreground {
        position: absolute;
        z-index: 5;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 900px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
            @include headline;
        }
        h3 {
            margin-bottom: 2rem;
        }
        .cell {
            position: relative;
            display: block;
            width: 540px;
            height: auto;
            margin-bottom: 2rem;
        }
        .anchor_links {
            display: flex;
            flex-direction: column;
            margin-top: 2rem;
            a {
                color: #fff;
                font-family: $font_headline;
                font-weight: 700;
                font-size: 1.7rem;
                text-decoration-color: $accent;
                text-decoration-thickness: 2px;
                text-underline-offset: 4px;
                opacity: 1;
                transition: opacity .2s;
                &:hover {
                    opacity: .75;
                }
            }
        }
    }
    .bottom {
        position: absolute;
        bottom: 50px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
            font-family: $font_headline;
            font-weight: 500;
            color: $accent2;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            svg {
                margin-left: 5px;
            }
        }  
    }
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        .foreground {
            top: 20vh;
            transform: translate(-50%, 0);
        }
    }
}

@include basic_mobile {
    .hero {
        .background {
            height: 600px;
            img {
                height: 100%;
                object-fit: cover;
            }
            .cell {
                width: 100%;
                height: 100%;
                .mediawrap {
                    height: 100%;
                    padding-top: unset !important;
                }
            }
        }
    }
}

@include phone_portrait {
    .hero {
        .background {
            height: 80vh;
            &.full {
                height: 100vh;
            }
        }
    }
    .about_film {
        .hero {
            .background {
                img {
                    object-position: 15% 0;
                }
            }
        }
    }
}

.grid.two_col {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

@include basic_mobile {
    .grid.two_col {
        gap: 1.5rem;
    }
}

@include phone_portrait {
    .grid.two_col {
        grid-template-columns: 1fr;
    }
}

.contact_form {
    position: relative;
    z-index: 5;
    width: calc(100% - 100px);
    min-height: 100vh;
    padding-top: 10rem;
    max-width: 900px;
    margin: 0 auto;
    .form_wrap {
        position: relative;
        width: 90%;
        margin: 5rem auto;
        form {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: flex-start;
            justify-content: space-between;
            .cell {
                width: calc(50% - 10px);
                margin-bottom: 30px;
                &.full {
                    width: 100%;
                }
                input, textarea {
                    width: 100%;
                    background-color: #526736;
                    padding: 20px 10px;
                    font-family: $font_primary;
                    font-size: 1.1rem;
                    &::placeholder {
                        font-family: $font_primary;
                        color: $text_primary;
                    }
                }

            }
            input[type="submit"] {
                justify-self: flex-end;
                margin: 0 0 0 auto;
                background-color: $accent;
                color: $background;
                cursor: pointer;
                padding: 20px 10px;
                width: calc(50% - 10px);
                font-family: $font_headline;
                font-weight: 900;
                font-size: 1.1rem;
            }
        }
    }
}

@include phone_portrait {
    .contact_form {
        width: calc(100% - 40px);
        h1.lines {
            span {
                font-size: 1.5rem;
            }
            svg {
                path {
                    fill: $accent;
                }
            }
        }
        .form_wrap {
            width: 100%;
            margin: 3rem auto;
            form {
                flex-direction: column;
                .cell {
                    width: 100%;
                    input, input[type="submit"] {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.awards {
    position: relative;
    width: calc(100% - 50px);
    max-width: 1200px;
    margin: 0 auto 5rem auto;
    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5rem 10rem;
        .cell {
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }

    }
}

@include basic_mobile {
    .awards {
        .grid {
            gap: 3rem 4rem;
        }
    }
}

@include phone_portrait {
    .awards {
        width: calc(100% - 40px);
        .grid {
            grid-template-columns: repeat(2, 1fr);
            gap: 3rem;
        }
    }
}

.press_kit {
    position: relative;
    z-index: 5;
    width: 100%;
    background-color: $accent3;
    padding: 5rem 0;
    margin: 0 auto 5rem auto;
    img.top_edge {
        position: absolute;
        top: -15px;
        left: 0;
        width: 100%;
        height: auto;
        display: block;
    }
    .inner {
        position: relative;
        width: 90%;
        max-width: 900px;
        margin: 0 auto;
        color: $text_secondary;
        text-align: center;
        h3 {
            margin-bottom: 2rem;
        }
        p {
            width: 90%;
            margin: 0 auto 2rem auto;
        }
        a {
            color: $accent2;
        }
        img.logo {
            display: block;
            width: 160px;
            height: auto;
            margin: 4rem auto 0 auto;
        }
        .columns {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            p {
                width: auto;
                margin: 0;
            }
            button {
                margin-left: 2rem;
            }
        }
    }
}

@include basic_mobile {
    .press_kit {
        img.top_edge {
            top: -5px;
        }
        .inner {
            width: calc(100% - 100px);
            p {
                width: 100%;
            }
            .columns {
                flex-direction: column;
                p.large {
                    font-size: 2rem;
                }
                button {
                    margin: 2rem auto 0 auto;
                }
            }
        }
    }
}

@include phone_portrait {
    .press_kit {
        img.top_edge {
            top: -2px;
        }
        .inner {
            width: calc(100% - 80px);
        }
    }
}

.stream_wrap {
    position: relative;
    width: 100%;
    background-color: $text_secondary;
    padding: 5rem 0 6rem 0;
    margin: 0 auto 5rem auto;
    img.top_edge_rough {
        position: absolute;
        top: -9px;
        left: 0;
        width: 100%;
        height: auto;
        display: block;
    }
    .inner {
        position: relative;
        width: 90%;
        max-width: 900px;
        margin: 0 auto;
        color: $text_primary;
        text-align: center;
        h3 {
            margin-bottom: 2rem;
        }
        p {
            width: 90%;
            margin: 0 auto 2rem auto;
        }
        img.logo {
            display: block;
            width: auto;
            height: 60px;
            margin: 0;
        }
        .columns {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            p {
                width: auto;
                margin: 0;
            }
            button {
                margin-left: 2rem;
            }
        }
    }
}

@include basic_mobile {
    .stream_wrap {
        img.top_edge_rough {
            top: -5px;
        }
        .inner {
            width: 85%;
            h3 {
                margin-bottom: 4rem;
            }
            img.logo {
                height: 43px;
            }
        }
    }
}

@include phone_portrait {
    .stream_wrap {
        img.top_edge_rough {
            top: -3px;
        }
        .inner {
            width: calc(100% - 40px);
            h3 {
                margin-bottom: 3rem;
            }
            .columns {
                flex-direction: column;
            }
            img.logo {
                margin-bottom: 3rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            
        }
    }
}

.contacts {
    position: relative;
    width: calc(100% - 100px);
    max-width: 900px;
    margin: 0 auto 10rem auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .cell {
        text-align: center;
        h3 {
            color: $accent;
            font-size: 1.3rem;
        }
        a {
            font-size: 2rem;
            color: #fff;
            transition: color .2s;
            text-decoration: none;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0px;
                width: 0;
                height: 1px;
                background-color: $accent;
                transition: width .2s;
            }
            &:hover {
                color: $accent;
                &:after {
                    width: 100%;
                }
            }
        }
    }
}

@include basic_mobile {
    .contacts {
        flex-direction: column;
        .cell {
            margin-bottom: 2rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@include phone_portrait {
    .contacts {
        width: calc(100% - 40px);
        .cell {
            h3 {
                font-size: 1rem;
            }
            a {
                font-size: 1.5rem;
            }
        }
    }
}

.centered {
    position: relative;
    width: calc(100% - 100px);
    margin: 3rem auto;
    padding: 3rem 0 1rem 0;
    .cell {
        video {
            opacity: 0;
            transition: opacity .3s;
        }
        &.playing {
            video {
                opacity: 1;
            }
            .playIcon {
                opacity: 0;
                transition: opacity .3s;
                pointer-events: none;
            }
        }
    }

}

@include basic_mobile {
    .centered {
        width: calc(100% - 40px);
        margin: 0 auto;
        padding: 1.5rem 0 1rem 0;
    }
}

@include mobile_portrait {
    .centered {
        width: calc(100% - 50px);
    }
}

.headline {
    position: relative;
    width: calc(100% - 100px);
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto 5rem auto;
    h2 {
        text-align: center;
        @include headline;
    }
}

@include phone_portrait {
    .headline {
        width: calc(100% - 40px);
        margin: 0 auto 3rem auto;
        h2 {
            font-size: 2.8rem;
            letter-spacing: .03rem;
        }
    }
}

.screenings {
    position: relative;
    z-index: 10;
    width: calc(100% - 50px);
    max-width: 1200px;
    margin: -3rem auto 4rem auto;
    min-height: 900px;
    background-image: url('/images/screenings_bg.png');
    color: $background;
    .wrap {
        padding: 2rem;
        ul {
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            li {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
        .header {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 0 0 0;
            .filter {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                .label {
                    font-family: $font_headline;
                    font-weight: 500;
                    font-size: .9rem;
                }
                ul {
                    margin-left: 15px;
                    li {
                        margin: 0 10px;
                    }
                }
            }
            .sort {
                position: relative;
                .sort_list {
                    background-color: transparent;
                    border: 1px solid $background;
                    color: $background;
                    border-radius: 5px;
                    font-family: $font_headline;
                    font-weight: 500;
                    font-size: .9rem;
                    padding: 5px;
                    .label {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: space-between;
                        padding: 10px;
                        svg {
                            width: 16px;
                            height: auto;
                            margin: 0 0 0 4rem;
                        }
                    }
                    .reveal {
                        position: relative;
                        width: 100%;
                        height: 0;
                        overflow: hidden;
                    }
                }
            }
            svg {
                display: block;
                width: 100%;
                margin: 40px auto 20px auto;
            }
        }
        .body {
            ul {
                li {
                    width: 100%;
                    background-color: transparent;
                    transition: background-color .3s;                    
                    a {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: flex-start;
                        width: 100%;
                        padding-top: 1rem;
                        text-decoration: none;
                    }
                    &:hover {
                        background-color: #fff;
                    }
                    .date {
                        width: calc(15% - 1rem);
                        margin-left: 1rem;
                        h3 {
                            font-size: 1rem;
                            color: $accent2;
                            letter-spacing: normal;
                        }
                        span {
                            font-size: .9rem;
                            color: $background;
                        }
                    }
                    .location {
                        width: calc(60% - 1rem);
                        margin-right: 1.5rem;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        h2 {
                            font-weight: 700;
                            font-size: 1.2rem;
                            color: $background;
                            letter-spacing: normal;
                        }
                        h3 {
                            color: $accent2;
                            font-size: 1.2rem;
                            font-weight: 700;
                            letter-spacing: normal;
                        }
                    }
                    .venue {
                        position: relative;
                        width: calc(25% - 1rem);
                        padding-left: 1.5rem;
                        color: $background;
                        .vert_line {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 1px;
                            height: 100%;
                            background-color: #fff;
                        }
                        h3 {
                            font-size: .9rem;
                            font-weight: 500;
                            letter-spacing: .02rem;
                        }
                        span {
                            font-size: .9rem;
                        }
                    }
                    .border {
                        display: block;
                        width: 100%;
                        margin-top: 1rem;
                        margin-bottom: -1px;
                    }
                }
            }
        }
        .footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 3rem 0;
        }
    }
}

@include basic_mobile {
    .screenings {
        margin: -2rem auto 2rem auto;
        .wrap {
            padding: 1.5rem;
            .header {
                padding: 0;
                .filter {
                    ul {
                        margin-left: 4px;
                        li {
                            margin: 0 5px;
                        }
                    }
                }
                .sort {
                    .sort_list {
                        .label {
                            padding: 6px;
                            svg {
                                margin: 0 0 0 1rem;
                            }
                        }
                    }
                }
                svg {
                    margin: 20px 0 10px 0;
                }
            }
            .body {
                ul {
                    li {
                        .date {
                            width: calc(25% - 1rem);
                        }
                        .location {
                            width: calc(75% - 1.5rem);
                            h3 {
                                display: none;
                            }
                        }
                        .venue {
                            display: none;
                        }                        
                    }
                }
            }
            .footer {
                padding: 2rem 0 1rem 0;
            }
        }
    }
}

@include phone_portrait {
    .screenings {
        width: calc(100% - 40px);
        margin: 2rem auto;
        .wrap {
            padding: 1.5rem 1rem;
            .header {
                flex-direction: column;
                .filter {
                    width: 100%;
                    .label {
                        display: none;
                    }
                    ul {
                        width: 100%;
                        margin: 0;
                        justify-content: space-between;
                        li {
                            margin: 0;
                        }
                    }
                }
                .sort {
                    width: 100%;
                    margin-top: 1rem;
                    .sort_list {
                        width: 100%;
                    }
                }
                svg {
                    margin: 10px 0 5px 0;
                }
            }
            .body {
                ul {
                    flex-direction: column;
                    li {
                        a {
                            flex-direction: column;
                            .date {
                                width: 100%;
                                margin: 0 0 5px 0;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: flex-end;
                                h3 {
                                    font-size: .9rem;
                                }
                                span {
                                    margin-left: 7px;
                                }
                            }
                            .location {
                                width: 100%;
                                margin: 0;
                                h2 {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.text_block {
    position: relative;
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    &.center {
        text-align: center;
        margin-bottom: 5rem;
        .inner {
            max-width: 900px;
            margin: 0 auto;
            p.large {
                color: $text_primary;
                opacity: 1;
            }
        }
        &.home_intro {
            margin-bottom: 0;
            .text {
                max-width: 900px;
                margin: 0 auto;
            }
        }
        &.wide {
            .inner {
                max-width: 1200px;
            }
        }
    }
    .inner {
        width: 100%;
        .anchor_links {
            display: flex;
            flex-direction: column;
            margin-top: 4rem;
            a {
                color: #fff;
                font-family: $font_headline;
                font-weight: 700;
                font-size: 1.7rem;
                text-decoration-color: $accent;
                text-decoration-thickness: 2px;
                text-underline-offset: 4px;
                opacity: 1;
                transition: opacity .2s;
                &:hover {
                    opacity: .75;
                }
            }
        }
        .trailer_cta {
            margin: 5rem 0 2rem 0;
            a {
                text-decoration: none;
            }
            p {
                font-family: $font_headline;
                font-size: 1rem;
                font-weight: 500;
                color: $accent2;
                opacity: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                svg {
                    margin-left: 10px;
                }
            } 
        }
        .columns {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;
            .column.media {
                width: 400px;
                img, video {
                    display: block;
                    width: 100%;
                    height: auto;
                    margin-bottom: 2rem;
                }
            }
            .column {
                width: calc(100% - 432px);
                &.center {
                    align-self: center;
                }
                &.left_margin {
                    width: calc(100% - 464px);
                }
            }
        }
        img.float_left {
            display: block;
            width: 400px;
            height: auto;
            float: left;
            margin: 0 2rem 2rem 0;
        }
        img.float_left_large {
            display: block;
            width: 440px;
            height: auto;
            float: left;
            margin: 0 2rem 2rem 0;
        }
        img.float_left_large + p {
            width: 90%;
        }
        img.float_right {
            display: block;
            width: 400px;
            height: auto;
            float: right;
            margin: 0 0 2rem 2rem;
        }
        img.absolute_right {
            display: block;
            width: 400px;
            height: auto;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;
        }
        .cell.stacked_logo {
            position: relative;
            display: block;
            width: 40%;
            max-width: 540px;
            height: auto;
            margin-bottom: 2rem;
            margin: 2rem auto;
        }
        p {
            font-size: 1.3rem;
            line-height: 1.5;
            color: #fff;
            opacity: .65;
            margin-bottom: 2rem;
            &.large {
                font-size: 1.6rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &.medium {
                font-size: 1.8rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        p.width_400 {
            width: calc(100% - 432px);
        }
        p.width_600 {
            width: 48%;
            margin-left: 5%;
        }
        p.width_600.left_margin {
            margin-left: 10%;
            width: 40%;
        }
    }
    &.narrow_width {
        max-width: 760px !important;
        margin: 0 auto 1rem auto;
    }
    &.medium {
        max-width: 900px !important;
        margin: 0 auto 2rem auto;
    }
    &.large {
        .inner {
            position: relative;
            max-width: 1200px;
            margin: 0 auto 1rem auto;
        }
    }
    &.bright {
        .inner {
            p {
                opacity: 1;
            }
        }
    }
    &.tan {
        .inner {
            p {
                color: $text_primary;
            }
        }
    }
}

.narrow {
    .text_block {
        width: 100%;
        .inner {
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
        }
    }
}

@include basic_mobile {
    .text_block {
        .inner {
            img.absolute_right {
                width: 310px;
            }
            p.width_400 {
                width: calc(100% - 350px);
                margin-top: 3rem;
            }
            p.width_600 {
                width: 75%;
                margin: 0 0 2rem 20%;
                &.left_margin {
                    width: 36%;
                    margin-left: 4%;
                    margin-top: 4rem;
                }
            }
            .anchor_links {
                margin-top: 4rem;
                a {
                    font-size: 1.1rem;
                    display: block;
                    padding: 5px 0;
                }
            }
            .cell.stacked_logo {
                width: 35%;
                margin: 2rem auto 4rem auto;
            }
            .trailer_cta {
                margin: 1rem 0 1rem 0;
                p {
                    font-size: 1rem;
                }
            }
            p {
                font-size: 1.4rem;
            }
            img.float_left, img.float_left_large, img.float_right {
                float: none;
                width: 100%;
                margin: 0 auto 1rem auto;
            }
            img.float_left_large + p {
                width: 100%;
            }
            .columns {
                .column {
                    width: calc(60% - 2rem);
                    p {
                        font-size: 1.1rem;
                    }
                }
                .column.media {
                    width: 40%;
                }
            }
        }
        &.center {
            margin-bottom: 4rem;
            .inner {
                p {
                    max-width: 540px;
                    margin: 0 auto;
                }
                
            }
        }
    }
    .foreground_wrap.full {
        .text_block {
            &.center {
                margin-bottom: 0;
            }
        }
    }
}

@include phone_portrait {
    .text_block {
        width: calc(100% - 40px);
        &.home_intro {
            .inner {
                p.large {
                    font-size: 1.2rem !important;
                }
            }
        }
        &.center {
            margin-bottom: 0;
        }
        .inner {
            .anchor_links {
                margin-top: 6rem;
                margin-bottom: 6rem;
            }
            .cell.stacked_logo {
                width: 55%;
                margin: 2rem auto 4rem auto;
            }
            .trailer_cta {
                position: absolute;
                bottom: 80px;
                left: 25px;
                width: calc(100% - 50px);
                text-align: center;
            }
            p {
                font-size: 1rem;
                &.large {
                    font-size: 1.4rem;
                }
            }
            .columns {
                flex-direction: column;
                .column {
                    width: 100% !important;
                    order: 2;
                    &.media {
                        order: 1;
                    }
                }
            }
            img.absolute_right, img.absolute_left {
                position: relative;
                width: 100%;
                top: auto;
                left: auto;
                right: auto;
                margin: 0 auto 2rem auto;
            }
            p.width_400, p.width_600 {
                margin: 0 auto 2rem auto !important;
                width: 100% !important;
                br {
                    display: none;
                }
            }
        }
    }
    .about_film {
        .text_block {
            .inner {
                p.large {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

/* specific image placement for inpiration page */
.inspiration_wrap {
    .text_block.last {
        padding-bottom: 125vh;
    }
    img.inspiration4 {
        position: absolute;
        top: -50px;
        right: 0;
        width: 325px;
        height: auto;
    }
    img.inspiration5 {
        position: absolute;
        top: 240px;
        right: 60px;
        width: 405px;
        height: auto;
    }
    img.inspiration6 {
        position: absolute;
        top: 550px;
        left: 0px;
        width: 405px;
        height: auto;
    }
    img.inspiration7 {
        position: absolute;
        top: 740px;
        left: 490px;
        width: 360px;
        height: auto;
    }
}

@media all and (min-width: 1800px) {
    .inspiration_wrap {
        .text_block.last {
            padding-bottom: 105vh;
        }
    }
}

@include basic_mobile {
    .inspiration_wrap {
        .text_block {
            &.last {
                padding-bottom: 60vh;
            }
            .inner {
                p {
                    font-size: 1.1rem;
                    &.large {
                        font-size: 1.4rem;
                    }
                }
                .columns {
                    .column.media {
                        width: 35%;
                        &.large {
                            width: 45%;
                        }
                    }
                    .column.text {
                        margin-top: -1rem;
                        &.left_margin {
                            width: calc(55% - 2rem);
                            margin-top: 3rem;
                        }
                    }
                    .column {
                        width: calc(62% - 2rem);
                    }
                }
            }
        }
        img.inspiration4 {
            width: 295px;
            top: 70px;
        }
        img.inspiration5 {
            width: 300px;
            top: 320px;
        }
        img.inspiration6 {
            width: 270px;
            top: 662px;
        }
        img.inspiration7 {
            left: 342px;
            width: 280px;
        }
    }
}

@include phone_portrait {
    .inspiration_wrap {
        margin-top: 3rem;
        padding-bottom: 0;
        .text_block {
            &.last {
                padding-bottom: 4rem;
            }
            .inner {
                p.tan.large {
                    text-align: center;
                    font-size: 1.4rem;
                }
                .columns {
                    .column.text {
                        order: 1;
                        text-align: center;
                        &.left_margin {
                            text-align: left;
                            margin-top: 0;
                        }
                    }
                    .column.media {
                        order: 2;
                        img {
                            width: 70%;
                            margin: 1rem auto 0 auto;
                        }
                        &.large {
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        img.inspiration4, img.inspiration5, img.inspiration6, img.inspiration7 {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            width: 100%;
            margin-bottom: 2rem;
        }
    }
}

.divider {
    position: relative;
    width: calc(100% - 100px);
    max-width: 900px;
    margin: 2rem auto 3rem auto;
    svg {
        width: 100%;
        height: auto;
        display: block;
    }
}

@include phone_portrait {
    .divider {
        width: calc(100% - 40px);
        margin: 1rem auto 2rem auto;
    }
}

.spacer {
    display: block;
    margin: 8rem auto 0 auto;
}

.bio {
    position: relative;
    width: 90%;
    max-width: 900px;
    margin: 0 auto 2rem auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    .media {
        width: 38%;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
        .title {
            margin-top: 10px;
            h3 {
                color: $accent;
                font-size: 1.64em;
                margin-bottom: 5px;
                letter-spacing: normal;
            }
            h4 {
                font-weight: 400;
                color: $accent3;
                font-size: 1.1rem;
            }
        }
    }
    .text {
        width: calc(62% - 2rem);
        p {
            font-size: 1.3rem;
            color: #fff;
            line-height: 1.7;
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@include basic_mobile {
    .bio {
        width: calc(100% - 100px);
    }
}

@include phone_portrait {
    .bio {
        width: calc(100% - 40px);
        flex-direction: column;
        .media {
            width: 100%;
            margin-bottom: 1rem;
            img {
                margin-bottom: 1rem;
            }
        }
        .text {
            width: 100%;
            p {
                font-size: 1rem;
            }
        }
    }
}

.pull_quote {
    position: relative;
    width: 100%;
    margin: 4rem auto;
    color: $accent;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img.line {
        display: block;
        width: 80%;
        height: auto;
    }
    .inner {
        margin: 2rem auto;
    }
    p {
        font-size: 2.5rem;
        font-weight: 500;
    }
}

@include basic_mobile {
    .pull_quote {
        img.line {
            width: 100%;
        }
        p {
            font-size: 1.6rem;
        }
    }
}

.logos {
    position: relative;
    width: 58%;
    margin: 6rem auto;
    img.line {
        display: block;
        width: 100%;
        height: auto;
    }
    .inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin: 3rem auto;
/*        .cell:first-child {
            width: 26%;
        }
        .cell:last-child {
            width: 57%;
        }*/
    }
}

@include basic_mobile {
    .logos {
        margin: 3rem auto;
        width: 80%;
    }
}

@include mobile_portrait {
    .logos {
        width: calc(100% - 50px);
        margin: 2rem auto;
        .inner {
            flex-direction: column;
            .cell {
/*                &:first-child {
                    width: 40%;
                    margin-bottom: 3rem;
                }
                &:last-child {
                    width: 80%;
                }*/
            }
        }
    }
}
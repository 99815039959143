$font_primary: adobe-garamond-pro, serif;
$font_headline: brandon-grotesque, sans-serif;

$accent: #EEC227;
$accent2: #EB823D;
$accent3: #FCEEC8;
$background: #5C7140;
$background2: #00001A;
$text_primary: #FAF7DC;
$text_secondary: #255A5E;
$text_gray: #777777;
@mixin laptop {
    @media all and (max-width: 1200px) {
        @content;
    }
}

.foreground_wrap {
    &.top {
        margin-top: 7rem;
    }

    .content {
        img.top_edge {
            position: static;
        }

        .wrap .narrow {
            width: 100%;
            padding: 2rem;
        }
    }

    .film_intro {
        .text_block .inner {
            img.float_left_large {
                margin: 0 2rem;
            }

            img.float_left_large + p {
                margin: 0 2rem;
                width: fit-content;
            }
        }

        .pull_quote {
            padding: 2rem;
            margin: auto;
        }
    }
}

h1.lines {
    span {
        &.medium {
            text-transform: uppercase;
        }
    }
}

button {
    &.headline_font {
        text-transform: uppercase;
    }
}

.centered {
    .cell {
        .mediawrap {
            .responsive-iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%
            }
        }
    }
}

.text_block.center {
    .inner {
        h1 {
            text-transform: uppercase;
        }

        p.large {
            color: #FFF;
        }
    }
}

.headline h2 {
    text-transform: uppercase;
    font-size: 3.5rem;
}

.dark_bg {
    .text_block {
        .inner {
            h1, h3 {
                text-transform: uppercase;
            }
        }
    }
}

.text_block.tan .inner {
    padding-top: 20px;
}

.inspiration_wrap {
    .text_block {
        &.last {
            padding-bottom: 900px;
        }

        .inner img.absolute_right {
            top: -24px;
        }
    }
}

.bio .text a {
    color: $accent;
}

.footer_disclaimer {
    width: 65%;
    padding: 20px;
    margin: 20px auto;
    border-top: 2px solid rgb(238, 194, 39);
    z-index: 5;

    p {
        margin-bottom: 1em;
    }
}

@include laptop {
    .foreground_wrap {
        &.full {
            margin-top: 4rem;
        }
    }
}

@include basic_mobile {
    .foreground_wrap {
        .intro {
            padding: 6rem 0 2rem;
            width: 93%;
        }

        .film_intro {
            padding: 0;
            width: calc(100% - 100px);

            .text_block .inner {
                img.float_left_large {
                    margin: 0;
                }

                img.float_left_large + p {
                    margin: 0;
                    padding: 2rem;
                }
            }
        }

        .text_block .inner {
            .columns {
                flex-direction: column;

                .column {
                    order: 2;
                    width: 100%;

                    p {
                        font-size: 1.4rem;
                    }
                }

                .column.media {
                    order: 1;
                    width: 100%;
                }
            }
        }
    }

    .inspiration_wrap .text_block {
        &.last {
            padding-bottom: 750px;
        }

        .inner p.width_600 {
            margin: 6rem 0 0 5%;
        }
    }

    .text_block .inner img.absolute_right {
        top: -44px;
    }
    
    .footer_disclaimer {
        width: 100%;
    }
}

@media all and (max-width: 896px) {
    .foreground_wrap {
        .intro {
            .text_block {
                width: calc(100% - 100px);
            }
        }

        .film_intro {
            .text_block {
                width: 100%;

                img.float_left_large + p {
                    font-size: 1.3rem;
                }
            }
        }

        .grid.two_col {
            grid-template-columns: 1fr;
        }
    }
}

@media all and (max-width: 768px) {
    .inspiration_wrap {
        .text_block {
            .inner {
                img.absolute_right {
                    top: 0;
                }

                p.tan.large {
                    font-size: 1.4rem;
                    text-align: center;
                }
            }
        }
    }
}

@media all and (max-width: 760px) {
    .inspiration_wrap {
        img {
            scale: 0.8;
        }

        img.inspiration4, img.inspiration5, img.inspiration6, img.inspiration7 {
            &.mobile_only {
                display: block !important;
            }

            &.desktop_only {
                display: none !important;
            }

            bottom: auto;
            left: auto;
            margin-bottom: 2rem;
            position: relative;
            right: auto;
            top: auto;
            width: 100%;
        }

        .text_block {
            &.last {
                padding-bottom: 4rem;
            }

            .inner {
                p.tan.large {
                    &.mobile_only {
                        display: block !important;
                    }

                    &.desktop_only {
                        display: none !important;
                    }
                }

                p.width_400, p.width_600 {
                    margin: 0 auto 2rem;
                    width: 100%;
                }

                img.absolute_right {
                    &.mobile_only {
                        display: block !important;
                        position: relative;
                        width: 100%;
                        margin-bottom: 2rem;
                    }

                    &.desktop_only {
                        display: none !important;
                    }
                }

                .columns {
                    flex-direction: column;

                    .column.media {
                        order: 2;
                        width: 100%;

                        img {
                            margin: 1rem auto 0;
                            width: 70%;
                        }

                        &.large {
                            width: 100%;

                            img {
                                width: 100%;

                                &.desktop_only {
                                    display: none !important;
                                }
                            }
                        }
                    }

                    .column.text {
                        order: 1;
                        text-align: center;
                        width: 100%;

                        &.left_margin {
                            margin-top: 0;
                            text-align: left;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@include phone_portrait {
    .foreground_wrap {
        &.full {
            margin-top: 0;
        }

        .intro {
            padding: 0;

            .text_block {
                width: 100%;
            }
        }

        .film_intro {
            width: calc(100% - 40px);

            .text_block .inner {
                img.float_left_large + p {
                    padding: 0;
                    margin: 1rem auto;
                }
            }

            .pull_quote {
                padding: 0;
            }
        }
    }

    .dark_bg .text_block .inner h1 {
        font-size: 2rem;
    }

    .inspiration_wrap {
        h3.lines span.small {
            font-size: 1.5rem;
        }

        img {
            scale: 1.0;
        }
    }
}